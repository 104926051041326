import { Fragment, h } from "preact";
import ErrorManager from "./ErrorManager";
import styles from "./App.module.css";
import Router, { route } from "preact-router";
import ConstructorRoute from "./routes/ConstructorRoute";
import NewRoute from "./routes/NewRoute";
import SuccessRoute from "./routes/SuccessRoute";
import ShippingRoute from "./routes/ShippingRoute";
import ManagerRoute from "./routes/ManagerRoute";
import useResizeReporter from "./useResizeReporter";
import { useEffect } from "preact/hooks";
import { Provider, useDispatch } from "react-redux";
import store from "../store";
import { fetchDefaults } from "../store/apiActions";

const Redirect = ({ to }) => {
  route(to, true);
};

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDefaults());
  }, []);

  useResizeReporter();
  useEffect(() => {
    document.title = "Конструктор товаров Mahrusha";
  }, []);
  return (
    <Fragment>
      <ErrorManager />
      <main id="app" className={styles.app}>
        <Router>
          <ConstructorRoute path="/constructor" />
          <ShippingRoute path="/shipping" />
          <ManagerRoute path="/manager" />
          {/* <NewRoute path="/new" />
          <SuccessRoute path="/success" /> */}
          <Redirect default to="/constructor" />
        </Router>
      </main>
    </Fragment>
  );
};

export default () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
