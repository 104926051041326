import { useEffect } from "preact/hooks";

const parentDomains =
  process.env.NODE_ENV === "development"
    ? ["http://localhost:5000"]
    : ["https://mahrusha.com", "https://www.mahrusha.com"];

/**
 * Это специально для загрузки в iframe: если от родителя по адресу parentDomains
 */
const useResizeReporter = () => {
  useEffect(() => {
    const handler = (event) => {
      if (!parentDomains.includes(event.origin)) {
        return;
      }
      document.body.style["min-height"] = "initial";
      document.body.style["min-width"] = "initial";

      const sendDataToParent = (parent, targetOrigin) => {
        parent.postMessage(
          {
            type: "resize.mahrusha",
            attributes: {
              height: document.body.scrollHeight,
              width: document.body.scrollWidth,
            },
          },
          targetOrigin
        );
      };
      sendDataToParent(event.source, event.origin);
      const resizeObserver = new ResizeObserver(() => {
        sendDataToParent(event.source, event.origin);
      });
      resizeObserver.observe(document.body);
    };
    window.addEventListener("message", handler, false);
    return () => window.removeEventListener("message", handler);
  }, []);
};

export default useResizeReporter;
